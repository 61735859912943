<template>
  <div>
    <client-only>
      <template #fallback>
        <SkeletonsHeroBanner></SkeletonsHeroBanner>
      </template>
      <ChannelHero
        :channels="channels"
        :active-channel="activeChannel"
        :active-program="activeProgram"
        :player-config="{}"
        :epg-config="epgConfig"
        @on-channel-change="(c) => $emit('onChannelChange', c)"
      >
      </ChannelHero>
    </client-only>
  </div>
</template>

<script setup>
import { useSportsStore } from "@/stores/sports";
const sportsStore = useSportsStore();
const props = defineProps({
  apiOptions: {
    type: Object,
    required: true,
  },
  kind: {
    type: String,
    required: true,
  },
  epgConfig: Object,
});

const router = useRouter();
const route = useRoute();

const channels = ref();
const activeChannel = ref();

const activeProgram = computed(() => {
  const program = activeChannel.value?.programs[0];

  const banner = useThumbnailOrientation(program, {
    orientation: "HorizontalImage",
  });

  return {
    ...program,
    genres: program?.genres || program?.genreEntityList,
    banner: encodeURI(banner),
  };
});

await fetchChannels();

onMounted(() => {
  setActiveChannel(route.params.channel || channels.value[0]);
});

watch(
  () => route.query,
  (newVal) => {
    if (Object.keys(newVal).includes("channel")) {
      setActiveChannel(route.query.channel);
    }
  },
  { deep: true }
);

async function fetchChannels() {
  const response = await useGetMethod(
    `/api${props.apiOptions.path}`,
    props.apiOptions.query
  );

  channels.value = response.map((r) => {
    return {
      ...r,
      images: useMediaAssets(r),
    };
  });
  //Store channel list based on Kind
  switch (props.kind) {
    case "sports":
      sportsStore.sportsChannelList = response || [];
      break;
  }
}

function setActiveChannel(channel) {
  activeChannel.value = channels.value.find(
    (c) => c.externalChannelId === channel || channel?.externalChannelId
  );
}
</script>

<style lang="scss" scoped></style>
