<template>
  <div
    :style="{
      backgroundImage: `url('${radioCover}')`,
    }"
    id="watchParent"
    class="relative w-full aspect-video lg:h-screen bg-center bg-no-repeat bg-cover flex justify-center items-center"
  >
    <div
      class="absolute z-0 top-0 start-0 w-full h-full gradient horizontal"
    ></div>
    <div
      class="absolute z-0 bottom-0 start-0 w-full h-1/2 gradient vertical"
    ></div>
    <div
      id="radioLogo"
      class="absolute z-10 top-0 w-full h-full py-16 flex justify-center items-center"
    >
      <div
        :style="{
          backgroundImage: `url('${activeChannel?.images.logo}')`,
        }"
        class="z-10 w-[109px] md:w-[204px] lg:w-[248px] aspect-square rounded-lg object-contain bg-center bg-contain bg-no-repeat"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { useRadioStore } from "@/stores/radio";

const radioStore = useRadioStore();

const props = defineProps({
  channels: {
    type: Array,
    required: true,
  },
  activeChannel: {
    type: Object,
    required: true,
  },
  activeProgram: {
    type: Object,
    required: true,
  },
  epgConfig: {
    type: Object,
    required: true,
  },
});

const radioCover = new URL(`@/assets/pngs/radio-cover.png`, import.meta.url)
  .href;

onMounted(async () => {
  // await fetchPlayingInfo();
  // await loadPlayer();

  radioStore.$patch({ showWidget: true });
});
</script>

<style lang="scss" scoped>
.slide-enter-active {
  animation: slide-in 0.2s ease-in-out;
}

.slide-leave-active {
  animation: slide-out 0.2s ease-in-out;
}

.slide-leave-to,
.slide-enter-from {
  transform: translateX(0px);
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0.1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0.1;
  }
}
</style>
