<template>
  <div>
    <template v-if="isXs">
      <div class="flex items-center gap-x-4 ps-4 md:ps-10 lg:ps-28 pb-4">
        <h2
          class="text-title-medium md:text-title-big lg:text-headline-small text-white ltr:tracking-[0.6px] rtl:tracking-normal font-bold"
        >
          {{ $t(titleOptions.label) }}
        </h2>
      </div>
      <CarouselElement
        v-if="isXs"
        :items="list"
        element-id="midpageSmCarousel"
        class="w-full"
      >
        <template #slider-item="{ item, key }">
          <button class="flex flex-col gap-y-2" @click="onChannelClick(item)">
            <div
              :key="key"
              :style="{
                backgroundImage: `url(${item.images.logo})`,
              }"
              class="thumbnail channel aspect-square mx-auto rounded-full bg-center bg-no-repeat bg-contain"
            ></div>
            <span
              class="text-center text-title-small text-white opacity-[0.87] line-clamp-2"
            >
              {{ item.name }}
            </span>
          </button>
        </template>
      </CarouselElement>
    </template>
    <template v-else>
      <div class="midpage-container relative w-full aspect-video">
        <swiper
          id="midpageCarousel"
          class="w-full h-full"
          thumbs-swiper=".mySwiper2"
          @swiper="(s) => (midpageSwiper = s)"
          @slide-change="
            ({ activeIndex }) => (selectedSlideIndex = activeIndex)
          "
        >
          <swiper-slide v-for="(item, key) in list" class="w-full h-full">
            <div
              :style="{
                backgroundImage: `url('${item.images.banner}')`,
              }"
              class="relative w-full h-full bg-center bg-no-repeat bg-cover"
            >
              <div
                id="gridContainer"
                class="z-10 absolute top-0 w-full aspect-[16/7] grid-container area-banner content-end px-4 md:px-10 lg:px-28"
              >
                <Transition name="fade-slide" mode="out-in">
                  <div id="logo" class="flex items-end">
                    <div
                      class="text-headline-small md:text-display-small lg:text-display-medium text-white opacity-[0.87]"
                    >
                      {{ item.programs[0]?.name }}
                    </div>
                  </div>
                </Transition>
                <Transition name="fade-slide" mode="out-in">
                  <div id="assetInfo" class="md:mt-8 flex flex-col gap-y-4">
                    <div>
                      <div class="flex flex-col gap-y-2">
                        <slot name="tags">
                          <OverlayFieldTags
                            :media="item.programs[0]"
                          ></OverlayFieldTags>
                          <OverlayDescriptionTags
                            :media="item.programs[0]"
                            opacity-class="opacity-80"
                          ></OverlayDescriptionTags>
                        </slot>
                      </div>
                      <OverlayDescription
                        v-if="!isXs"
                        :media="item.programs[0]"
                      ></OverlayDescription>
                    </div>
                  </div>
                </Transition>
                <Transition name="fade-slide" mode="out-in">
                  <div id="cta" class="mt-6 rounded-lg flex gap-x-4 pb-16">
                    <ButtonsCta
                      class="variant-primary py-2"
                      @click="onChannelClick(item)"
                    >
                      <div class="w-full flex items-center gap-x-[6px]">
                        <Icon name="IconPlay" size="24"></Icon>
                        <span
                          class="font-Abu md:text-button-medium text-button-big text-white"
                        >
                          {{ $t("Join") }}
                        </span>
                      </div>
                    </ButtonsCta>
                  </div>
                </Transition>
              </div>
              <div
                class="absolute top-0 z-0 w-full h-full gradient horizontal"
              ></div>
              <div
                class="absolute bottom-0 z-0 w-full h-1/2 gradient vertical"
              ></div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="absolute bottom-0 z-20 w-full">
          <client-only>
            <CarouselSection
              :items="list"
              :title-options="titleOptions"
              :api-options="apiOptions"
              carousel-id="midpageBannerCarousel"
              hideViewAllBtn
            >
              <template #slider-item="{ item, key }">
                <div
                  :class="[
                    selectedSlideIndex === key
                      ? '  border-white/80'
                      : 'border-transparent',
                  ]"
                  class="thumbnail channel aspect-square h-full border-2 bg-white/10 rounded-full backdrop-blur-sm"
                  @click.stop="onCarouselItemClick(key)"
                >
                  <div
                    :key="key"
                    :style="{
                      backgroundImage: `url(${item.images.logo})`,
                    }"
                    class="w-full h-full rounded-full bg-center bg-no-repeat bg-contain"
                  ></div>
                </div>
              </template>
            </CarouselSection>
          </client-only>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
const { apiOptions, titleOptions } = defineProps({
  /**
   * label: 'Recommended for you',
   * path: '/recommended',
   */
  titleOptions: {
    type: Object,
    required: true,
  },
  /**
   * type: Object,
   * query: Object,
   */
  apiOptions: {
    type: Object,
    required: true,
  },

  thumbnailOptions: {
    type: Object,
    default: () => null,
  },
});
const { isPlusNeedPopup } = useNeedLoginPopup();
const midpageSwiper = ref();
const swiperRef = ref();
const selectedSlideIndex = ref(null);
const localePath = useLocalePath();
const { isXs, breakpoints } = useBreakpoints();

const response = await useGetMethod(`/api${apiOptions.path}`, apiOptions.query);
const list = response.map((l) => {
  return {
    ...l,
    images: useMediaAssets(l),
  };
});

const onCarouselItemClick = (key) => {
  selectedSlideIndex.value = key;
  midpageSwiper.value.slideTo(key, 300);
};

const traverseCarousel = (val) => {
  const swiper = swiperRef.value.$el.swiper;

  if (val > 0) {
    swiper.slideNext(300);
  } else {
    swiper.slidePrev(300);
  }
};

const onChannelClick = (channel) => {
  if (isPlusNeedPopup(channel)) return;
  navigateTo({
    path: localePath(`/live/${channel.externalChannelId}`),
    query: {
      autoplay: true,
    },
  });
};

// trigger the watcher to get first asset's info
onMounted(() => {
  selectedSlideIndex.value = 0;
});

/**
 * collection of validations that will determine whether
 * to show the separator between the dynamic string on the left
 * and the genre list
 */
const showSeparator = (item) => {
  if (!item) return;
  const { type, tvShowSeason, tvShowSeries } = item || {};
  const hasSeasonNumber = type == "Season" && tvShowSeason.name;
  const hasSeriesNumberOfSeasons =
    type == "Series" && tvShowSeries.numberOfSeasons;
  const hasGenres = item?.genreList?.length > 0;

  return (hasSeasonNumber || hasSeriesNumberOfSeasons) && hasGenres;
};
</script>

<script>
export default { name: "MidpageBanner" };
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
