<template>
  <div>
    <client-only>
      <template #fallback>
        <SkeletonsHeroBanner></SkeletonsHeroBanner>
      </template>
      <RadioHero
        :channels="channels"
        :active-channel="activeChannel"
        :active-program="activeProgram"
        :player-config="{}"
        :epg-config="epgConfig"
        @on-route-change="onRouteChange"
      >
      </RadioHero>
    </client-only>
  </div>
</template>

<script setup>
import { useRadioStore } from "@/stores/radio";
const radioStore = useRadioStore();
const props = defineProps({
  apiOptions: {
    type: Object,
    required: true,
  },
  kind: {
    type: String,
    required: true,
  },
  epgConfig: Object,
});

const router = useRouter();
const route = useRoute();

const channels = ref();
const activeChannel = ref();

const activeProgram = computed(() => {
  const program = activeChannel.value?.programs[0];

  const images = useMediaAssets(program);

  if (program) {
    program.genres = program.genres || program.genreEntityList;
  }

  if (images) {
    program.images = images;
    program.banner = images?.banner;
  }

  return program || {};
});

await fetchChannels();

onMounted(() => {
  setActiveChannel(route.query.channel || channels.value[0]);

  // if a detail dialog is open, avoid replacing the route
  if (Object.keys(route.query).includes("media")) {
    return;
  }

  if (activeChannel.value) {
    router.replace({
      path: route.path,
      query: {
        station: activeChannel.value.externalChannelId,
      },
    });
  }
});

watch(
  () => route.query.station,
  (newVal) => {
    if (!newVal) return;
    setActiveChannel(route.query.station);
  },
  { deep: true }
);

async function fetchChannels() {
  const response = await useGetMethod(
    `/api${props.apiOptions.path}`,
    props.apiOptions.query
  );

  radioStore.channels = channels.value = response.map((r) => {
    return {
      ...r,
      images: useMediaAssets(r),
    };
  });
}

function setActiveChannel(channel) {
  activeChannel.value = channels.value.find(
    (c) => c.externalChannelId === channel || channel?.externalChannelId
  );

  radioStore.activeChannel = activeChannel.value;
}
</script>

<style lang="scss" scoped></style>
