<template>
  <div class="hero-item relative w-full h-full border-inherit">
    <Transition name="bannerfade" mode="out-in" @after-enter="onAfterEnter">
      <template v-if="transitionState == 'banner'">
        <div
          v-if="bannerToShow"
          :style="{
            backgroundImage: `url(${bannerToShow})`,
          }"
          :class="[imageClass]"
          class="w-full h-full bg-center bg-no-repeat bg-cover"
        ></div>
        <div
          v-else
          :class="[imageClass]"
          class="w-full h-full aspect-video bg-center bg-no-repeat bg-cover flex items-center justify-center"
        >
          <img :src="useFallbackImage().url.value" alt="" />
        </div>
      </template>

      <div v-else class="w-full h-full">
        <video
          :muted="isMuted"
          :class="[imageClass]"
          :id="`trailerContainer${index}`"
          ref="trailerContainer"
          playsinline
          muted
          autoplay
          class="z-0 w-full h-full"
        ></video>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";
import { useElementVisibility } from "@vueuse/core";

import { useDetailStore } from "@/stores/detail";
import { usePlayerStoreWithID } from "@/stores/playerWithId";
import { PLAYERS } from "@/constants";
import { useGetStartedStore } from "@/stores/getStarted";

const props = defineProps({
  item: Object,
  index: Number,
  isMuted: {
    type: Boolean,
    default: () => true,
  },
  isActive: {
    type: Boolean,
    default: () => false,
  },
  allowTrailer: {
    type: Boolean,
    default: () => true,
  },
  imageClass: String,
  playerId: String,
  inDetail: Boolean,
});

const sessionStore = useSessionStore();

const emit = defineEmits(["on-next", "on-trailer-start"]);

const detailStore = useDetailStore();
const route = useRoute();
const playerStore = ref();
playerStore.value = usePlayerStoreWithID(
  props.playerId || PLAYERS.HERO_PLAYER
)();
const getStartedStore = useGetStartedStore();

const transitionState = ref("banner");
const timer = ref();
const trailerContainer = ref();

const targetIsVisible = useElementVisibility(trailerContainer);

const parsedItem = computed(() => {
  if (props.inDetail) {
    return {
      ...props.item.media,
      images: props.item.media.images || useMediaAssets(props.item.media),
      trailer: props.item.trailer,
    };
  }

  return {
    ...props.item,
    images: useMediaAssets(props.item),
  };
});

/**
 * for live program:
 * in herobanner: if no banner in place, use channel banner
 */
const bannerToShow = computed(() => {
  try {
    const itemObj = props.inDetail ? props.item.media : props.item;

    if (!itemObj.images) {
      itemObj.images = useMediaAssets(itemObj);
    }

    const imageAssetsEmpty =
      Object.keys(itemObj.images)?.length === 0 || !itemObj.images?.banner;
    const hasChannel = Object.keys(itemObj).includes("channel");

    if (itemObj.type == "LiveProgram" && imageAssetsEmpty && hasChannel) {
      const images = useMediaAssets(itemObj.channel);
      return images.banner;
    }
    return parsedItem.value.images?.banner;
  } catch (error) {
    return parsedItem.value.images?.banner;
  }
});

onMounted(async () => {
  await nextTick();
  transitionState.value = "banner";
  const hasMediaQuery = Object.keys(route.query).includes("media");
  const hasDetailStore = detailStore.trailer;

  if (hasDetailStore && props.inDetail) {
    const hasTrailer = Object.keys(detailStore?.trailer || {})?.length > 0;
    if (!hasTrailer) return;
    initializeTimer();
  }

  if (hasMediaQuery === false && !props.inDetail) {
    initializeTimer();
  }
});

onBeforeUnmount(() => {
  timer.value && clearTimeout(timer.value);
  transitionState.value = "banner";
});

const bannerTrailer = computed(() => {
  return parsedItem.value.trailer || parsedItem.value.attachmentPlayingInfo;
});

/**
 * initializeTimer
 * start the transition from banner to trailer
 *
 * goes through validations first
 * 1) is it has a trailer
 * 2) if detail modal is open (hasDetailStore) and this component is in detail
 * 3) if detail modal is not open and this component is not in detail
 */
const initializeTimer = () => {
  if (
    sessionStore.activeProfile?.property?.autoplay_previews === false ||
    getStartedStore.modalState
  ) {
    return;
  }

  if (!bannerTrailer.value) {
    return;
  }

  timer.value = setTimeout(() => {
    timer.value && clearTimeout(timer.value);
    transitionState.value = "trailer";
  }, 4500);
};

const onAfterEnter = async () => {
  if (transitionState.value == "trailer" && props.isActive) {
    await playTrailer();
    emit("on-trailer-start", true);
  }
};

watch(
  () => props.isMuted,
  () => {
    if (playerStore.value?.player) {
      playerStore.value.player.muted = props.isMuted;
      playerStore.value.player.video_.muted = props.isMuted;
    }
  }
);

watch(
  () => props.allowTrailer,
  (newVal) => {
    if (newVal === false) {
      transitionState.value = "banner";
      playerStore.value?.player.destroy();
      emit("on-trailer-start", false);
    }
  }
);

// when trailer ends, emit onNext to show the next item in the carousel
watch(
  () => playerStore.value?.playbackState,
  async (newVal) => {
    if (newVal == "ended") {
      await nextTick();
      transitionState.value = "banner";
      await playerStore.value?.player?.destroy();
      playerStore.value?.$reset();
      emit("on-next");
    }
  }
);

watch(
  () => props.isActive,
  async (newVal) => {
    if (newVal) {
      transitionState.value = "banner";
      initializeTimer();
    } else {
      clearTimeout(timer.value);
      transitionState.value = "banner";
      await playerStore.value?.player?.destroy();
      playerStore.value?.$reset();
    }
  },
  { deep: true }
);

/**
 * track if trailer is visible
 */
watch(targetIsVisible, (isVisible) => {
  if (!isVisible) {
    playerStore.value?.pause();
  } else {
    playerStore.value?.play();
  }
});
const playTrailer = async () => {
  await playerStore?.value?.player?.destroy();
  await playerStore.value.initialize(`trailerContainer${props.index}`);
  await playerStore?.value?.player?.reset();
  await playerStore.value.loadMedia(
    { url: bannerTrailer.value.url },
    {
      extraOpts: {
        autostart: true,
      },
    },
    {},
    {
      sesContentType: "Trailer",
      sesSessionType: "Trailer",
    }
  );
};

watch(
  () => route.query,
  (newQuery) => {
    if (Object.keys(newQuery).includes("media")) {
      transitionState.value = "banner";
      /**
       * trigger on next if it's on detail page.
       * this triggers the onTrailerEnd and hides the trailer controls.
       */
      const isShowOrSeason = ["Series", "Season"].includes(route.query.type);
      if (props.inDetail && isShowOrSeason) {
        nextTick().then(() => {
          emit("on-next");
          clearTimeout(timer.value);
          initializeTimer();
        });
        return;
      }

      clearTimeout(timer.value);
      return;
    }

    transitionState.value = "banner";

    initializeTimer();
  }
);

watch(
  () => getStartedStore.modalState,
  (newVal) => {
    if (newVal) {
      clearTimeout(timer.value);
    } else {
      initializeTimer();
    }
  }
);
</script>

<script>
export default { name: "BannerItem" };
</script>

<style lang="scss" scoped>
.bannerfade-enter-active,
.bannerfade-leave-active {
  transition: opacity 0.5s ease 500ms;
}

.bannerfade-leave-to,
.bannerfade-enter-from {
  opacity: 0;
}
</style>
