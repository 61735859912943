export const fetchLayout = async (path, query = {}) => {
  const { status, code, response } = await useGetMethod(path, query, {
    raw: true,
  });

  if (status == 200 && code == "SUCCESS") {
    return response;
  } else {
    throw createError("Unable to fetch page layout");
  }
};

export const fetchCarousel = async ({ path, query }) => {
  const { status, code, response } = await useGetMethod(`/api${path}`, query, {
    raw: true,
  });

  if (status == 200 && code == "SUCCESS") {
    if (Array.isArray(response)) {
      return response.map((r) => {
        return {
          ...r,
          genreList: r.genreEntityList?.map((g) => g.name),
          trailer: r.attachmentPlayingInfo,
          images: useMediaAssets(r),
        };
      });
    }

    return response;
  } else {
    throw createError("Unable to load this component");
  }
};
