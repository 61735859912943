import { defineStore } from "pinia";

export const useSportsStore = defineStore("sports", {
  state: () => ({
    channels: null,
    activeChannel: null,
    //Sports Channel List
    sportsChannelList: [],
    /**radio related states */
    player: null,
    channel: null,
    isPlaying: false,
    isMuted: false,
    isFullscreen: false,

    /**
     * ui related state
     */
    transitionState: "banner",
    preventHideUI: false,
    showHoveredContent: true,
    hideTimeout: null,
    showChannelList: true,
    transitionTimeout: null,
  }),
  getters: {
    videoQualityList() {
      return useGenerateVideoQualityList(this.player);
    },
    currentProgram() {
      const program = this.activeChannel?.programs[0];

      return {
        ...program,
        bannerImg: useThumbnailOrientation(program, {
          orientation: "HorizontalImage",
        }),
      };
    },
  },
  actions: {
    async fetchChannels() {
      const response = await useGetMethod("/api/biz/live/channel/list", {
        external_category_id: "web_home_chooseyourstation",
      });

      this.channels = response.map((r) => {
        const logo = useThumbnailOrientation(r, {
          orientation: "Logo",
        });
        const hori = useThumbnailOrientation(r, {
          orientation: "Banner",
        });

        return {
          ...r,
          logo: encodeURI(logo),
          hori: encodeURI(hori),
        };
      });
    },
    togglePlayback() {
      if (this.player?.playing_) {
        this.player?.pause();
        this.isPlaying = false;

        this.onPause();

        return;
      }
      this.player?.play();
      this.isPlaying = true;
      this.onPlay();
      clearTimeout(this.hideTimeout); // Clear any existing timeout.
    },
    toggleVolume() {
      this.isMuted = !this.isMuted;
      this.player.volume = this.isMuted ? 0 : 1;

      this.player.video_.muted = this.isMuted;
    },

    onPlay() {
      this.transitionState = "player";
      this.showChannelList = false;
      this.hideUi();
      clearTimeout(this.transitionTimeout);
    },

    onPause() {
      this.transitionTimeout = setTimeout(() => {
        clearTimeout(this.transitionTimeout);
        this.player?.destroy();
        this.showChannelList = true;
        this.transitionState = "banner";
      }, 2500);
    },

    hideUi() {
      if (this.preventHideUI) return;
      clearTimeout(this.hideTimeout);
      this.hideTimeout = setTimeout(() => {
        clearTimeout(this.hideTimeout); // Clear any existing timeout.
        this.showHoveredContent = false;
      }, 3000);
    },
  },
  persist: [
    {
      key: "sportsChannelList",
      storage: persistedState.sessionStorage,
      paths: ["sportsChannelList"],
    },
  ],
});
