<template>
  <div class="relative">
    <div class="lg:absolute lg:top-0 z-20" v-if="showBackBtnAlways">
      <div class="flex">
        <div id="content" class="z-10 w-full lg:pt-32 xl:pt-32 h-28">
          <div class="ps-4 md:ps-10 lg:ps-28 flex items-center gap-x-4 h-full">
            <UiBackWithTitle :title="props?.layout?.title" />
          </div>
        </div>
      </div>
    </div>
    <template v-if="hasBanner">
      <PageBuilderHeroBanner
        v-if="heroBannerConfig"
        :api-options="heroBannerConfig.api"
        :class="isXlAndUp ? 'absolute top-0 start-0' : 'relative'"
      ></PageBuilderHeroBanner>
      <ChannelHeroBuilder
        v-if="liveBannerConfig"
        :kind="kind"
        :api-options="liveBannerConfig.api"
        :epg-config="{
          label: 'Schedule',
          basePath: kind,
          epgPath: liveBannerConfig.epgPath,
          epgQuery: liveBannerConfig.api.query,
        }"
      ></ChannelHeroBuilder>

      <RadioHeroBuilder
        v-if="radioBannerConfig"
        :api-options="radioBannerConfig.api"
        :epg-config="{
          label: 'Schedule',
          basePath: kind,
          epgPath: radioBannerConfig.epgPath,
          epgQuery: radioBannerConfig.api.query,
        }"
      ></RadioHeroBuilder>

      <PageBuilderSpacer
        v-if="hasBanner && isXlAndUp && heroBannerConfig"
      ></PageBuilderSpacer>
    </template>
    <template v-else>
      <div
        class="z-0 max-lg:relative lg:absolute top-0 w-full aspect-video object-cover bg-center bg-cover bg-no-repeat max-lg:-mb-[calc(30vh)]"
      >
        <div class="absolute w-full h-full gradient horizontal"></div>
        <div class="absolute w-full h-full gradient vertical"></div>
        <div
          :style="{ backgroundImage: `url(${pageBG})` }"
          class="w-full h-full bg-no-repeat bg-cover bg-center"
        ></div>
      </div>
      <div class="flex">
        <div
          id="content"
          class="z-10 w-full pt-6 md:pt-32 h-28 lg:h-[calc(45vh)]"
        >
          <div class="ps-4 md:ps-10 lg:ps-28 flex items-center gap-x-4"></div>
        </div>
      </div>
    </template>

    <section
      v-for="(group, key) in parsedLayout"
      :key="key"
      :class="group.gradientDirection"
      class="z-10 gradient radial flex flex-col gap-y-10 md:gap-y-14 lg:gap-y-16 pb-16"
    >
      <div
        :key="key"
        v-for="(section, key) in group.carousels"
        class="flex flex-col gap-y-10 md:gap-y-14 lg:gap-y-16"
      >
        <PageBuilderCarousel
          v-if="showCarousel(section)"
          :carousel-id="useKebabCase(section.id)"
          :title-options="section.title"
          :thumbnail-options="section.thumbnail_options"
          :api-options="section.api"
          :carousel-type="section.carouselType"
        ></PageBuilderCarousel>

        <PageBuilderEpg
          v-if="section?.type === 'epg'"
          :title="section?.title?.label"
          :query="radioBannerConfig.api.query"
        >
        </PageBuilderEpg>

        <PageBuilderMidpage
          v-if="section.carouselType == 'channels'"
          :title-options="section.title"
          :thumbnail-options="section.thumbnail_options"
          :api-options="section.api"
          class="w-full"
        ></PageBuilderMidpage>

        <GridHomeSection
          v-if="section.carouselType === 'up_tiles'"
          :title-options="section.title"
          :thumbnail-options="section.thumbnail_options"
          :api-options="section.api"
        >
        </GridHomeSection>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";

// isLgAndUp
const { isXs, isLgAndUp, isXlAndUp } = useBreakpoints();
const { direction } = useCurrentLocale();
const sessionStore = useSessionStore();

const props = defineProps({
  layout: [Array, Object],
  kind: String,
  showBackBtnAlways: {
    type: Boolean,
    default: () => false,
  },
});

const parsedLayout = ref();

onMounted(async () => {
  await nextTick();
  parsedLayout.value = useParsePageLayout(props.layout);
});

onBeforeUnmount(async () => {
  useAllPlayers().resetPassives();
});

const pageBG = computed(() => {
  let mediaUrl;
  if (process.server) {
    mediaUrl = process.env.IMG_HOST;
  } else {
    mediaUrl = useAppConfig().mediaUrl;
  }
  let url = `${mediaUrl}${props?.layout?.backgroundImg}`;
  return encodeURI(url);
});

const hasBanner = computed(() => {
  return (
    heroBannerConfig.value || liveBannerConfig.value || radioBannerConfig.value
  );
});

const heroBannerConfig = computed(() => {
  return props?.layout?.carousels?.find((c) => c.carouselType == "hero_banner");
});

const liveBannerConfig = computed(() => {
  const config =
    props?.layout?.carousels?.find(
      (section) =>
        section.type === "player_banner" &&
        section.carouselType === "live_channel_player"
    ) ?? null;
  if (!config) return null;

  return {
    ...config,
    epgPath: `/schedule/${config.api?.query?.external_category_id}`,
  };
});

const radioBannerConfig = computed(() => {
  const config =
    props?.layout?.carousels?.find(
      (section) =>
        section.type === "player_banner" &&
        section.carouselType === "radio_channel_player"
    ) ?? null;
  if (!config) return null;

  return {
    ...config,
    epgPath: `/schedule/${config.api?.query?.external_category_id}`,
  };
});

/**
 * if this carousel requires a token,
 * requireLogin will be present and will be true.
 *
 * in that case check for token from sessionStore
 */
const showCarousel = (section) => {
  const containsLoginRequirement =
    Object.keys(section).includes("requireLogin");

  const validCarouselTypes = [
    "thumbnail_with_overlay",
    "standard_thumbnail",
    "big_thumbnail",
    "portrait",
    "thumbnail_with_duration",
    "thumbnail_with_progress",
    "top_ten",
    "big_subjects",
    "subjects",
    "big_square_thumbnail",
    "square_thumbnail",
  ];

  const isOfType = validCarouselTypes.includes(section.carouselType);

  if (!isOfType) return false;
  if (!containsLoginRequirement) return true;
  const show = section.requireLogin === true && sessionStore.isAuthenticated;
  return show;
};
</script>

<script>
export default { name: "PageBuilder" };
</script>

<style lang="scss" scoped></style>
