<template>
  <div class="relative gap-y-4">
    <h2
      class="pb-4 text-title-medium md:text-title-big lg:text-headline-small text-white opacity-[0.87] px-4 md:px-10 lg:px-28"
    >
      {{ $t(titleOptions.label) }}
    </h2>

    <div class="grid-container gap-2 md:gap-4 lg:gap-6 px-4 md:px-10 lg:px-28">
      <div
        :key="key"
        v-for="(item, key) in parsedList"
        class="col-span-6 md:col-span-8 lg:col-span-12 flex flex-col gap-y-2"
      >
        <NuxtLink :to="item.path" class="flex flex-col gap-y-2">
          <div
            :style="{
              backgroundImage: `url(${item.images.pillarimage})`,
            }"
            class="w-full aspect-video rounded-lg relative bg-center bg-no-repeat bg-cover"
          >
            <div class="absolute top-0 w-full p-4 flex justify-between">
              <span
                class="text-title-medium text-white drop-shadow-lg uppercase"
              >
                {{ item.contentType }}
              </span>
            </div>
          </div>
          <h4 v-if="item.name" class="text-title-medium text-white opacity-[0.87]">
            {{ item.name }}
          </h4>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
const { titleOptions, apiOptions, thumbnailOptions } = defineProps({
  /**
   * label: 'Recommended for you',
   * path: '/recommended',
   */
  titleOptions: {
    type: Object,
    required: true,
  },
  /**
   * showTitle: true,
   * showProgress: true,
   * type: 'portrait', // null for landscape, other values: top_10, live
   * size: 'medium',
   */
  thumbnailOptions: {
    type: Object,
    required: true,
  },
  /**
   * type: Object,
   * query: Object,
   */
  apiOptions: {
    type: Object,
    required: true,
  },
});

const route = useRoute();
const localePath = useLocalePath();

const options = {
  from: 0,
  count: 10,
  ...apiOptions.query,
};
const list = await useGetMethod(`/api${apiOptions.path}`, options);

const parsedList = computed(() => {
  const parsed = list.contents.map((c) => {
    return {
      ...c,
      images: useMediaAssets(c),
      path: getViewAllLocalePath(c),
    };
  });

  return parsed;
});

const getPageTypeFromNuxtMeta = route?.meta?.pageType
  ? `${route?.meta?.pageType}`
  : null;

const getViewAllLocalePath = (item) => {
  const externalCategoryId = item.extrafields.find(
    (e) => e.name == "external_category_id"
  );
  const storageId = `directory-${externalCategoryId.value}`;
  const apiOptions = {
    path: "/biz/program/unified",
    query: {
      external_category_id: externalCategoryId.value,
      client: "json",
    },
  };
  sessionStorage.setItem("parentRoute", route.path);
  sessionStorage.setItem(storageId, JSON.stringify(apiOptions));
  return localePath(`/directory/${externalCategoryId.value}`);
};
</script>

<script>
export default { name: "GridHomeSection" };
</script>

<style lang="scss" scoped></style>
