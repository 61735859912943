<template>
  <div
    :style="{
      backgroundImage: `url('${radioCover}')`,
    }"
    class="relative w-full aspect-video lg:h-screen bg-center bg-no-repeat bg-cover"
  >
    <div
      class="absolute z-0 top-0 start-0 w-full h-full gradient horizontal"
    ></div>
    <div
      class="absolute z-0 bottom-0 start-0 w-full h-1/2 gradient vertical"
    ></div>
    <div class="w-full h-full flex gap-x-4">
      <div
        class="banner__overlay absolute top-0 start-0 w-full h-full flex flex-col lg:flex-row items-center"
      >
        <div
          class="overlay__grid w-full h-full grid-container content-center auto-rows-min px-4 md:px-10 lg:px-28 gap-2 md:gap-4 lg:gap-6"
        >
          <CarouselTypeBigSquare
            v-if="hasActiveProgram && hasSquareImage"
            :item="activeProgram"
            :show-title="false"
            thumb-class="like-square"
          ></CarouselTypeBigSquare>
          <CarouselTypeBigSquare
            v-else
            :item="{
              images: {
                squareimage: activeChannel.images.logo,
              },
            }"
            :key="activeChannel"
            :show-title="false"
            thumb-class="like-square bg-contain"
          ></CarouselTypeBigSquare>
          <div
            class="flex flex-col justify-end items-baseline gap-y-2 lg:gap-y-6 col-span-6 md:col-span-9 lg:col-span-9"
          >
            <span
              class="text-headline-small md:text-display-small lg:text-display-medium text-white opacity-[0.87] line-clamp-2"
            >
              {{ activeProgram?.name }}
            </span>
            <div class="flex flex-col gap-y-4">
              <div class="flex gap-x-2 items-center">
                <UiTagLive
                  :size="isXs ? 'small' : 'big'"
                  v-if="useIsLive(activeProgram)"
                ></UiTagLive>
                <div class="flex flex-wrap items-center gap-x-1 break-words">
                  <span
                    class="text-body-small lg:text-label-medium text-white opacity-80"
                  >
                    {{ activeProgram?.category }}
                  </span>
                  <span v-if="showSeparator" class="px-1 text-white opacity-50">
                    |
                  </span>
                  <div class="flex flex-wrap items-center gap-x-1 break-words">
                    <span
                      :key="key"
                      v-for="(g, key) in activeProgram?.genres"
                      class="text-body-small lg:text-body-medium text-white"
                    >
                      {{ g.name }}
                      <Icon
                        v-if="key < activeProgram?.genres?.length - 1"
                        name="IconDot"
                        class="fill-white"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <p
                v-if="!isXs && activeProgram?.description"
                class="md:text-body-medium lg:text-body-big tracking-wider text-white opacity-[0.87] line-clamp-1 md:line-clamp-3"
              >
                {{ activeProgram?.description }}
              </p>
            </div>
            <ButtonsCta
              class="variant-primary flex-shrink h-9 lg:h-12"
              @click.stop="$emit('onBannerClick')"
            >
              <div class="flex gap-x-2 items-center">
                <Icon
                  name="IconPlay"
                  :size="isXs ? 20 : 32"
                  class="rtl:-scale-x-100"
                ></Icon>
                <span class="text-button-medium lg:text-button-big">
                  {{ $t("Listen Now") }}
                </span>
              </div>
            </ButtonsCta>
          </div>
        </div>
        <div
          class="hidden lg:block absolute top-0 end-0 h-full pt-[calc(5rem)]"
        >
          <RadioHeroChannelListVertical
            v-if="epgConfig"
            :channels="channels"
            :path="epgConfig.basePath"
          >
            <template #epg>
              <NuxtLink :to="localePath(epgConfig.epgPath)">
                <ButtonsCta
                  class="w-full variant-secondary text-button-medium py-2"
                  custom-class
                >
                  {{ $t(epgConfig.label) }}
                </ButtonsCta>
              </NuxtLink>
            </template>
          </RadioHeroChannelListVertical>
        </div>
        <div id="channelListHori" class="block lg:hidden w-screen">
          <RadioHeroChannelListHorizontal
            v-if="epgConfig"
            :channels="channels"
            :path="epgConfig.basePath"
            :epgPath="epgConfig.epgPath"
          >
            <template #epg>
              <div class="grid justify-items-end pr-4">
                <NuxtLink
                  class="inline-flex"
                  :to="localePath(epgConfig.epgPath)"
                >
                  <ButtonsCta
                    class="w-full variant-secondary !text-button-small md:w-[117px] md:!text-button-medium py-2 px-4"
                    custom-class
                  >
                    {{ $t(epgConfig.label) }}
                  </ButtonsCta>
                </NuxtLink>
              </div>
            </template>
          </RadioHeroChannelListHorizontal>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const localePath = useLocalePath();
const props = defineProps({
  activeProgram: {
    type: Object,
    requried: true,
  },
  activeChannel: {
    type: Object,
    requried: true,
  },
  channels: {
    type: Array,
    requried: true,
  },
  epgConfig: {
    type: Object,
    requried: true,
  },
});

const emit = defineEmits("onBannerClick");

const radioCover = new URL(`@/assets/pngs/radio-cover.png`, import.meta.url)
  .href;

const { isXs } = useBreakpoints();

const hasActiveProgram = computed(() => {
  return props.activeProgram && Object.keys(props.activeProgram).length > 0;
});

const hasSquareImage = computed(() => {
  if (!hasActiveProgram.value) return false;
  return Object.keys(props.activeProgram.images).includes("squareimage");
});

const onGuideClick = () => {
  const guideTitle = useCookie("guide_title");
  guideTitle.value = "Schedule";

  const {
    epgPath,
    epgQuery: { external_category_id },
  } = props.epgConfig;
  const path = `${epgPath}/${external_category_id}`;
  navigateTo({
    path: localePath(path),
  });
};
</script>

<script>
export default { name: "RadioBannerOverlay" };
</script>

<style lang="scss" scoped></style>
