<template>
  <client-only>
    <Epg
      v-if="!isLoading"
      :title="props.title"
      :show-back="false"
      :channels="channels"
      :isShowDate="false"
    />
  </client-only>
</template>

<script setup>

const props = defineProps({
  query: {
    type: Object,
  },
  title: {
    type: String,
    default: "Schedule",
  },
});

const { data: channels, pending: isLoading } = await useAsyncData(
  "channels",
  async () => {
    const query = {};
    Object.assign(query, props.query);
    const res = await useFetchAsset().fetchChannels(query);
    return res;
  },
  {
    server: false,
  }
);
</script>

<style lang="scss" scoped></style>
