<template>
  <Transition
    name="fade"
    mode="out-in"
  >
    <RadioHeroBanner
      v-if="transitionState == 'banner'"
      :channels="channels"
      :active-channel="activeChannel"
      :active-program="activeProgram"
      :epg-config="epgConfig"
      @on-banner-click="handlePlay"
    ></RadioHeroBanner>
    <RadioHeroPlayer
      v-else
      :channels="channels"
      :active-channel="activeChannel"
      :active-program="activeProgram"
      :epg-config="epgConfig"
    ></RadioHeroPlayer>
  </Transition>
</template>

<script setup>
import { useRadioStore } from "@/stores/radio";
const radioStore = useRadioStore();
const { FMVideoStart } = useADtvGTM();

const props = defineProps({
  channels: {
    type: Array,
    required: true,
  },
  activeChannel: {
    type: Object,
    required: true,
  },
  activeProgram: {
    type: Object,
    required: true,
  },
  epgConfig: {
    type: Object,
    required: true,
  },
});

const transitionState = ref("banner");

radioStore.$subscribe((mutation, state) => {
  if (Object.keys(mutation?.payload || {}).includes("showWidget")) {
    transitionState.value = mutation.payload.showWidget ? "player" : "banner";
  }
});

const { isPlusNeedPopup } = useNeedLoginPopup();
const handlePlay = () => {
  if (!isPlusNeedPopup(props.activeChannel)) {
    radioStore.$patch((state) => (state.widgetStation = props.activeChannel));
    transitionState.value = "player";
    const isLive = radioStore.widgetStation.programs?.[0]
      ? useIsLive(radioStore.widgetStation.programs?.[0])
      : true;
    FMVideoStart(
      props.activeChannel,
      isLive,
      radioStore.widgetStation.programs?.[0]
    );
  }
};
</script>

<script>
export default { name: "RadioHero" };
</script>

<style lang="scss" scoped></style>
