<template>
  <div class="h-full py-4 px-2 flex flex-col gap-y-2 bg-brand-greyblack-300/90">
    <div class="h-full flex flex-col gap-y-2 overflow-y-auto">
      <button
        v-for="channel in channels"
        :class="[isSelected(channel) ? 'bg-white/30' : 'bg-white/10']"
        class="flex-shrink-0 rounded-[4px] thumbnail small aspect-video"
        @click="onChannelClick(channel)"
      >
        <div
          :style="{ backgroundImage: `url('${channel.images.logo}')` }"
          class="h-full w-full rounded-[4px] object-contain bg-center bg-contain bg-no-repeat"
        ></div>
      </button>
    </div>
    <slot name="epg"> </slot>
  </div>
</template>

<script setup>
const props = defineProps({
  channels: {
    type: Array,
    required: true,
  },
  path: String,
});

const localePath = useLocalePath();
const router = useRouter();
const route = useRoute();

const isSelected = (channel) => {
  const externalChannelId = route.query.station;
  return externalChannelId === channel.externalChannelId;
};

const onChannelClick = (channel) => {
  // :to="{
  //         path: localePath(`/${path}`),
  //         query: {
  //           channel: channel.externalChannelId,
  //         },
  //       }"
  router.replace({
    path: localePath(`/${props.path}`),
    query: {
      station: channel.externalChannelId,
    },
  });
};
</script>

<style lang="scss" scoped></style>
