<template>
  <div
    class="page-builder-carousel"
    :style="{
      zIndex,
    }"
  >
    <client-only>
      <template #fallback></template>
      <CarouselSection
        v-if="parsedList.length > 0"
        :key="apiOptions.path"
        :hide-view-all-btn="list && (list.size || list.length) < 20"
        :items="parsedList"
        :title-options="parsedTitleOptions"
        :carousel-id="carouselId"
        :carousel-options="carouselOptions"
        :apiOptions="apiOptions"
        :carouselType="carouselType"
        carousel-class="no-overflow"
        @is-dragging="(e) => (carouselDisabled = e)"
        @on-hover="(carouselId) => (isHovering = carouselId)"
      >
        <template #slider-item="{ item, key, isHovered }">
          <div
            v-if="carouselId == 'live-sports'"
            class="text-white"
          >
            <CarouselTypeBig
              :item="item"
              force-live
              @click.stop="onFloaterClick(item)"
            ></CarouselTypeBig>
          </div>
          <floater-v4
            v-else
            :is-hovered="isHovered"
            :element-id="`${carouselId}-floater-${key}`"
            :floater-image="item.imageToShow"
            :top-position="topPosition"
            :media="item"
            :disabled="disableFloater(item)"
            :class="[{ 'font-bold': $route.path.includes('kids') }]"
            :aspect-ratio="item.isPodcast ? 'aspect-square' : 'aspect-video'"
            thumb-class="bg-pink-100"
            @click="onFloaterClick(item)"
            :gtmTitleLabel="parsedTitleOptions?.label"
          >
            <template #triggerContent>
              <CarouselType
                :type="carouselType"
                :item="item"
                :carousel-index="key"
              ></CarouselType>
            </template>
          </floater-v4>
        </template>
      </CarouselSection>
    </client-only>
  </div>
</template>

<script setup>
const { asDurationPretty } = useDateTime();

const {
  carouselId,
  titleOptions,
  apiOptions,
  thumbnailOptions,
  carouselType,
  floaterOptions,
} = defineProps({
  /**
   * label: 'Recommended for you',
   * path: '/recommended',
   */
  titleOptions: {
    type: Object,
    required: true,
  },
  /**
   * showTitle: true,
   * showProgress: true,
   * type: 'portrait', // null for landscape, other values: top_10, live
   * size: 'medium',
   */
  thumbnailOptions: {
    type: Object,
    required: true,
  },
  /**
   * type: Object,
   * query: Object,
   */
  apiOptions: {
    type: Object,
    required: true,
  },
  carouselId: {
    type: String,
    required: true,
  },
  carouselOptions: {
    type: Object,
    default: () => null,
  },
  carouselType: {
    type: String,
  },
  carouselClass: String,
  /**
   * disabled: true/false,
   * onClick: func
   */
  floaterOptions: Object,
});

const localePath = useLocalePath();
const carouselDisabled = ref(false);
const isHovering = ref();

let count = 20;
if (carouselType == "top_ten") {
  count = 10;
}
const options = {
  from: 0,
  ...apiOptions.query,
  count,
};

const list = ref();
list.value = await useGetMethod(`/api${apiOptions.path}`, options);

/**
 * added condition for personalized category
 * since the title comes from the request and
 * not on the carousel config
 */
const parsedTitleOptions = computed(() => {
  let label = titleOptions.label;
  if (Object.keys(list.value).includes("category_title")) {
    label = list.value.category_title;
  }
  return {
    ...titleOptions,
    label,
  };
});

const zIndex = computed(() => {
  const hasModal = "media" in useRoute().query;

  if (!hasModal && isHovering.value != null && isHovering.value == carouselId) {
    return 60;
  } else {
    return 40;
  }
});

/**
 * mapping of the floater's top positioning based
 * on the carousel type
 */
const topPosition = computed(() => {
  switch (carouselType) {
    case "portrait":
    case "big_square_thumbnail":
      return "-top-8";
    case "square_thumbnail":
    case "top_ten":
      return "-top-24";

    default:
      return "-top-32";
  }
});

const orientation = computed(() => {
  switch (thumbnailOptions?.card_type) {
    case "portrait":
    case "top_10":
      return "VerticalImage";
    case "cirlce":
      return "logo";

    default:
      return "HorizontalImage";
  }
});

const isSport = (item) => item?.channelExternalId?.includes("ad_sports");
const disableFloater = (item) => {
  if (floaterOptions?.disabled) return true;

  const mediaDeterminator = useMediaType(item);

  /**
   * special case:
   * if carouselId is LiveSports,
   * disable floater
   */
  if (carouselId == useKebabCase("LiveSports")) {
    return true;
  }

  /**
   * special case:
   * if the item is either
   * Majid Song,
   * Podcast,
   * Episode,
   * disable the floater
   */
  if (
    mediaDeterminator.isMajidSong ||
    mediaDeterminator.isPodcastEpisode ||
    mediaDeterminator.isEpisode
  ) {
    return true;
  }

  const isOfItemType = ["Theme", "Browser", "Player", "Genre"].includes(
    item.type
  );

  const isOfCarouselType = [
    "big_subjects",
    "thumbnail_with_progress",
    "thumbnail_with_overlay",
  ].includes(carouselType);

  const isSportType = isSport(item) || false;
  const customLogic = isOfItemType || isOfCarouselType || isSportType;
  return useDisableFloater(customLogic);
};

const parsedList = computed(() => {
  let _list = [];
  if (!list.value) return [];

  if (Array.isArray(list.value)) {
    _list = list.value;
  } else {
    _list = list.value.contents || [];
  }

  return _list.map((l) => {
    const { startDate, endDate } = l;
    let durationPretty;
    if (startDate && endDate) {
      durationPretty = asDurationPretty((endDate - startDate) / 1000);
    }
    const item = {
      ...l,
      imageUrl: useThumbnailOrientation(l, { orientation: orientation.value }),
      images: useMediaAssets(l),
      durationPretty,
      playButtonConfig: useCtaItems(l),
      isPodcast: l.contentType?.toUpperCase() == "PODCAST",
    };

    return {
      ...item,
      imageToShow: item.isPodcast
        ? item.images?.squareimage
        : item.images?.horizontalimage,
    };
  });
});

const onFloaterClick = (item) => {
  if (floaterOptions?.onClick) {
    return floaterOptions.onClick(item);
  }

  /**
   * if there's a ChannelHeroBuilder component, the banner & player changes
   * by the channel query. and the sports page features such component.
   *
   * then let's check if the clicked item is of type LiveProgram.
   *
   * combine these two checks and you get to solve AN-26 by simply scrolling
   * to the top of the page to display the channel.
   *
   * ## Live sports carousel on sports page
   * - The live sports carousel on the sports page doesn’t move users
   * - to the live stream player when choosing a channel.
   */
  if (item.type == "LiveProgram" && useRoute().name.includes("sports")) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }

  /**
   * special case:
   * if carouselId is LiveSports,
   * it should navigate to the sports page with the selected channel
   */
  if (carouselId == useKebabCase("LiveSports")) {
    return navigateTo({
      path: useLocalePath()("/sports"),
      query: {
        channel: item.channelExternalId,
      },
    });
  }

  return useFloaterClick(item, carouselType);
};
</script>

<script>
export default { name: "CarouselIndex" };
</script>

<style lang="scss" scoped></style>
