<template>
  <div class="w-full">
    <SkeletonsHeroBanner v-if="isLoading"></SkeletonsHeroBanner>
    <template v-else>
      <div v-if="hasError">Unable to load this page {{ hasError }}</div>
      <template v-else>
        <PageBuilderHeroBannerAsCarousel
          v-if="isXs"
          :is-loading="isLoading"
          :has-error="hasError"
          :items="items"
        ></PageBuilderHeroBannerAsCarousel>
        <PageBuilderHeroBannerAsBanner
          v-else
          :is-loading="isLoading"
          :has-error="hasError"
          :items="items"
        ></PageBuilderHeroBannerAsBanner>
      </template>
    </template>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";

const { fetchTrailer } = useFetchAsset();
const props = defineProps({
  apiOptions: Object,
});

const sessionStore = useSessionStore();

const isLoading = ref(true);
const hasError = ref(false);
const items = ref();
const itemsTrailers = ref();

const { isXs } = useBreakpoints();

onMounted(async () => {
  try {
    const response = await fetchCarousel(props.apiOptions);
    items.value = response;

    // Prefetch trailers
    const trailers = await Promise.all(
      response.contents.map(async (item) => {
        if (sessionStore.activeProfile?.property?.autoplay_previews === false) {
          return null;
        }

        return await fetchTrailer(item);
      })
    );

    itemsTrailers.value = trailers;
    items.value.contents = items.value.contents.map((item, i) => {
      return {
        ...item,
        ...itemsTrailers.value[i],
        images: useMediaAssets(item),
      };
    });
  } catch (error) {
    console.error(error);
    hasError.value = true;
  }

  isLoading.value = false;
});
</script>

<script>
export default { name: "HeroBanner" };
</script>

<style lang="scss" scoped></style>
