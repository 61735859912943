export default function (item, tagName) {
  if (!item) return null;

  const { type, extrafields } = item;

  const isVod = ["Movie", "Season", "Series"].includes(type);
  const acceptableTag = ["isNew", "Top10", "Exclusive"].includes(tagName);
  if (!acceptableTag && !isVod) {
    return null;
  }

  const extraField = extrafields.find((e) => e.name == tagName);
  if (!extraField) return null;

  return extraField;
}
