<template>
  <div class="w-full aspect-video">
    <div
      class="hero-banner__gradients z-10 w-full h-full absolute top-0 start-0 touch-none pointer-events-none"
    >
      <div class="w-full h-full gradient horizontal"></div>
      <div
        class="w-full h-1/2 absolute bottom-0 start-0 gradient vertical"
      ></div>
    </div>
    <!-- persistent components that should be outside of the banner item -->
    <!-- pb-0 md:pb-24 lg:pb-0 -->
    <PageBuilderHeroBannerOverlay
      :item="activeItem"
      :active-index="activeIndex"
      :is-trailer-started="trailerStarted"
      class="z-10 w-full sm:max-xl:h-full lg:aspect-[16/7] absolute top-0 start-0 px-4 md:px-10 lg:px-28 grid-container area-banner content-end"
    >
      <template #controller>
        <PageBuilderHeroBannerTrailerControls
          v-if="trailerStarted"
          v-model:isMuted="isMuted"
          @on-stop-trailer="allowTrailer = false"
        ></PageBuilderHeroBannerTrailerControls>
      </template>
      <template #navigator>
        <PageBuilderHeroBannerNavigator
          :active-index="activeIndex"
          :list="items.contents"
          :item="activeItem"
          @on-prev="traverseSwiper(-1)"
          @on-next="traverseSwiper(1)"
        ></PageBuilderHeroBannerNavigator>
      </template>
    </PageBuilderHeroBannerOverlay>

    <swiper
      loop
      slides-per-view="auto"
      class="w-full h-full"
      @swiper="(s) => (swiperEl = s)"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="(item, key) in items.contents"
        class="max-w-min w-full aspect-video h-full"
      >
        <PageBuilderHeroBannerItem
          :key="`banner-item-${key}`"
          :is-active="key == activeIndex"
          :index="key"
          :item="item"
          :isMuted="isMuted"
          :allow-trailer="allowTrailer"
          class="w-full h-full aspect-video"
          @on-trailer-start="onTrailerStart"
          @on-next="traverseSwiper(1)"
        ></PageBuilderHeroBannerItem>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { getActivePinia } from "pinia";
import { PLAYERS } from "@/constants";

const props = defineProps({
  items: Object,
});

const route = useRoute();

const swiperEl = ref();
const activeIndex = ref(0);
const trailerStarted = ref(false);
const isMuted = ref(true);
const allowTrailer = ref(true);

const activeItem = computed(() => {
  const item = props.items.contents[activeIndex.value];
  return {
    ...item,
    images: useMediaAssets(item),
    bannerTags: {
      new: useShowBannerTag(item, "isNew"),
      top10: useShowBannerTag(item, "Top10"),
      digi: useShowBannerTag(item, "Exclusive"),
    },
  };
});

const onSlideChange = ({ realIndex: index }) => {
  allowTrailer.value = true;
  activeIndex.value = index;
  trailerStarted.value = false;
};

const traverseSwiper = (step) => {
  if (step > 0) {
    swiperEl.value.slideNext(300);
  } else {
    swiperEl.value.slidePrev(300);
  }

  trailerStarted.value = false;
};

const onTrailerStart = (val) => {
  trailerStarted.value = val;
};

/**
 * finds the instance of the radio player
 * might return undefined if there were no radio player
 * instantiated.
 */
const radioPlayer = computed(() => {
  const allStores = getActivePinia()._s;
  return allStores.get(PLAYERS.RADIO_PLAYER);
});

/**
 * watch for radio player's playback state
 * when user clicks play on a paused widget,
 * oldVal will be paused and newVal will be playing
 *
 * in that scenario, isMuted.value should be true
 * meaning the attention is now on the widget and
 * no longer in the trailers
 */
watch(
  () => radioPlayer.value?.playbackState,
  (newVal, oldVal) => {
    if (oldVal == "paused" && newVal == "playing") {
      isMuted.value = true;
    }
  }
);

/**
 * watch for hero banner's trailer
 * when user chooses to unmute,
 * the radio player should be paused
 */
watch(
  () => isMuted.value,
  (newVal) => {
    // handle widget playback here
    if (newVal === false) {
      radioPlayer.value?.pause();
    }
  }
);

watch(
  () => route.query,
  (newQuery) => {
    if (Object.keys(newQuery).includes("media")) {
      trailerStarted.value = false;
      return;
    }
  }
);
</script>

<script>
export default { name: "HeroAsBanner" };
</script>

<style lang="scss" scoped></style>
