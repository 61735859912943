/**
 * from https://raji.viaccess-orca.com/browse/ADMUI-43
 * Building your list of Qualities:
 * You shall use the 'height' field in the returned qualities array to create the list of available quality in the streams.
 * You shall add Auto on top of this list to handled when ABR is ON or OFF.} player
 */
export default function (player) {
  let qualities = [];

  if (player?.qualities) {
    qualities = player.qualities.map((q) => ({ ...q, label: `${q.height}p` }));
  }

  qualities.push({
    id: -1,
    label: "Auto",
  });

  return qualities;
}
