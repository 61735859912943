<template>
  <ButtonsIcon
    iconName="IconBack"
    size="24"
    class="variant-glass rtl:rotate-180"
    @click="useBackEvent"
  >
  </ButtonsIcon>
</template>

<script setup lang="ts">
</script>
