import { useSessionStore } from "@/stores/session";

export default function (data) {
  const groupedData = [];
  let currentGroup = [];
  let prevType = null;
  let gradientDirection = "from-left";

  // Flag to track the order of carousel groups
  let isSecondCarouselGroup = false;
  // Loop through the data
  for (const item of data?.carousels ?? []) {
    const sessionStore = useSessionStore();

    // dont include in parsed layout when this carousel needs auth and is anonymous
    if (item.requireLogin && !sessionStore.isAuthenticated) {
      continue;
    }
    // Check if the current item's type is different from the previous item's type
    if (item.type !== prevType) {
      // If there are items in the current group, push it to the groupedData array
      if (currentGroup.length > 0) {
        // Determine whether to add the alternating class or set it to null
        groupedData.push({
          carousels: currentGroup,
          gradientDirection:
            prevType === "carousel"
              ? isSecondCarouselGroup
                ? "from-right"
                : "from-left"
              : null,
        });

        // Switch the flag if the current group is a carousel group
        if (prevType === "carousel") {
          isSecondCarouselGroup = !isSecondCarouselGroup;
        }
      }

      // Reset currentGroup
      currentGroup = [];
    }

    // Add the current item to the currentGroup
    currentGroup.push(item);
    prevType = item.type; // Update prevType for the next iteration
  }

  // After the loop, check if there are any remaining items in currentGroup
  if (currentGroup.length > 0) {
    // Determine whether to add the alternating class or set it to null
    groupedData.push({
      carousels: currentGroup,
      gradientDirection:
        currentGroup.length > 2 && prevType === "carousel"
          ? isSecondCarouselGroup
            ? "from-right"
            : "from-left"
          : null,
    });
  }

  return groupedData;
}
