<template>
  <div class="flex justify-end gap-x-4 items-center">
    <div
      v-if="isMdAndUp && list.length <= 10"
      class="navigator__dots flex-1 flex gap-x-2 justify-end items-center"
    >
      <template :key="key" v-for="(i, key) in list.length">
        <div
          :class="[key == activeIndex ? 'w-10' : 'w-2']"
          class="w-10 h-2 relative ease-in-out duration-500"
        >
          <div
            v-if="key == activeIndex"
            :style="{ width: `${width}%` }"
            class="absolute h-full rounded-full bg-white ease-linear duration-1000"
          ></div>
          <div class="bg-white/50 w-full h-full rounded-full"></div>
        </div>
      </template>
    </div>
    <div
      class="navigator__controls flex gap-x-2 items-center pointer-events-auto"
    >
      <ButtonsCta
        custom-class
        class="w-9 h-9 variant-glass"
        @click.stop="emit('on-prev')"
      >
        <Icon name="IconChevronLeft" size="20" class="rtl:-scale-x-100"></Icon>
      </ButtonsCta>
      <ButtonsCta
        custom-class
        class="w-9 h-9 variant-glass"
        @click.stop="emit('on-next')"
      >
        <Icon name="IconChevronRight" size="20" class="rtl:-scale-x-100"></Icon>
      </ButtonsCta>
    </div>
  </div>
</template>

<script setup>
import { useInterval } from "@vueuse/core";
import { useSessionStore } from "@/stores/session";
import { useGetStartedStore } from "@/stores/getStarted";

const props = defineProps({
  list: Array,
  item: Object,
  activeIndex: Number,
});

const emit = defineEmits(["on-next", "on-prev"]);

const sessionStore = useSessionStore();
const route = useRoute();

const { direction } = useCurrentLocale();
const { isLgAndUp, isMdAndUp } = useBreakpoints();

const dotTimer = useInterval(1000, { controls: true });

const duration = ref(5);
const getStartedStore = useGetStartedStore();

onMounted(() => {
  setDuration();
});

const width = computed(() => {
  const w = ((dotTimer?.counter.value ?? 0) / duration.value) * 100;
  return Math.min(w, 100);
});

const setDuration = () => {
  if (sessionStore.activeProfile?.property?.autoplay_previews === false) {
    duration.value = 5.5;
    return;
  }

  duration.value = props.item.trailerDuration / 1000 + 5 || 5;
};

/**
 * watcher to trigger next events
 */
watch(
  () => dotTimer.counter.value,
  (newVal) => {
    if (newVal > duration.value) {
      emit("on-next");
    }
  }
);

/**
 * watcher to reset everything
 */
watch(
  () => props.activeIndex,
  () => {
    setDuration();
    dotTimer.reset();
  }
);

watch(
  () => route.query,
  (newQuery) => {
    if (Object.keys(newQuery).includes("media")) {
      dotTimer.reset();
      dotTimer.pause();
    } else {
      dotTimer?.resume();
    }
  }
);

watch(
  () => getStartedStore.modalState,
  (newVal) => {
    if (newVal) {
      dotTimer.pause();
    } else {
      dotTimer?.resume();
    }
  }
);
</script>

<style lang="scss" scoped></style>
