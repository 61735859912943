<template>
  <div>
    <CarouselElement
      :items="items.contents"
      element-id="heroCarousel"
      class="w-full"
    >
      <template #slider-item="{ item, key }">
        <CarouselTypePortrait
          :item="item"
          image-to-use="verticalimage"
          @click="onItemClick(item)"
        ></CarouselTypePortrait>
      </template>
    </CarouselElement>
  </div>
</template>

<script setup>
const props = defineProps({
  items: Object,
  isLoading: {
    type: Boolean,
    default: true,
  },
  hasError: {
    type: Boolean,
    default: true,
  },
});

const onItemClick = (item) => {
  item.playButtonConfig = useCtaItems(item);
  useFloaterClick(item);
};
</script>

<style lang="scss" scoped></style>
